import React, { useContext, useEffect, useRef, useState } from "react";
import { TopbarExtension } from "components/topbarExtension";
import { AllPatientsTable } from "./allPatientsTable";
import { ExtensionItems } from "./extensionItems";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import {
  PatientListContext,
  PatientListContextState
} from "contextApis/patientsListContext";
import { CheckboxMenuCommonInterface } from "sharedTypes";
import { useDebounce } from "helpers/customHooks";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";

type Patient = "My Patients" | "Remote Monitoring" | "Others";

interface TableRow {
  id: number;
  first_name: string;
  last_name: string;
  age: number;
  visit_date: string;
  notification_level: string;
  tasks: string;
  picture: string;
  dob: string;
  name: string;
  internal_id: string;
  appointment_id: string;
  remote_monitoring: string;
  username: string;
  phoneNumbers: {
    title: string;
    number: string;
  }[];
  vbc: string;
}

export const PatientsPage: React.FC = () => {
  const epicLogin: any = sessionStorage.getItem("epicLogin");
  const patientListContext =
    useContext<PatientListContextState>(PatientListContext);
  const patientContext = useContext<PatientContextState>(PatientContext);

  const [allDiagnosisChecked, setAllDiagnosisChecked] = useState(false);
  const [diagnosisFilterApplied, setDiagnosisFilterApplied] = useState(true);
  const [patientType, setPatientType] = useState<Patient>("My Patients");
  const [vbcPlan, setVBCPlan] = useState("Any");
  const [vbcPlanName, setVBCPlanName] = useState("Any");
  const [vbcList, setVBCList] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedDiagnosesItemCodes, setSelectedDiagnosesItemCodes] =
    useState("");

  const debouncedSearchValue = useDebounce(searchValue);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [patientList, setPatientList] = useState<TableRow[]>([]);
  const [hasMoreData, setHasMoreData] = useState(true);

  const {
    data: dataDisableComponent,
    isLoading: isLoadingDisableComponent,
    refetch: refetchDisableComponent
  } = useQuery(
    "disableComponent",
    async () => {
      if (patientContext.orgId) {
        return createApiClient().getDisableContent(patientContext.orgId);
      } else {
        throw new Error("orgId is missing");
      }
    },
    {
      enabled: !!patientContext.orgId
    }
  );

  useEffect(() => {
    if (patientContext.orgId) {
      refetchDisableComponent();
    }
  }, [patientContext.orgId]);

  useEffect(() => {
    localStorage.setItem(
      "disableComponent",
      JSON.stringify(dataDisableComponent)
    );
  }, [dataDisableComponent]);

  const {
    isLoading: isLoadingDiagnosesList,
    data: diagnosesData,
    error: isDiagnosesListError,
    refetch: refetchDiagnosescode
  } = useQuery(
    "diagnoses-list",
    async () => {
      if (patientContext.orgId) {
        return await createApiClient().getDiagnosesList(
          true,
          patientContext.orgId
        );
      }
    },
    {
      enabled: !!patientContext.orgId
    }
  );

  useEffect(() => {
    if (patientContext.orgId) {
      refetchDisableComponent();
      refetchDiagnosescode();
    }
  }, [patientContext.orgId]);

  const {
    isLoading: isLoadingVbcPrograms,
    data: vbcPrograms,
    error: isVbcProgramsError,
    isFetching: isFetchingVbcPrograms,
    refetch: refetchVbcPrograms
  } = useQuery(
    "vbcPrograms",
    async () => {
      const data = await createApiClient().getVBCPrograms();
      setVBCList(data);
    },
    {
      enabled: true
    }
  );

  const {
    isLoading: isLoadingPatientList,
    data: patientData,
    error: isPatientListError,
    refetch: getPatientList,
    isFetching: isPatientListRefetchLoading
  } = useQuery<TableRow[], Error>(
    [
      "all-patients-list",
      debouncedSearchValue,
      patientType,
      vbcPlan,
      selectedDiagnosesItemCodes,
      pageNumber
    ],
    async () => {
      if (sessionStorage.getItem("patientType")) {
        setPatientType(sessionStorage.getItem("patientType") as Patient);
      }
      if (sessionStorage.getItem("searchText")) {
        setSearchValue(String(sessionStorage.getItem("searchText")));
      }
      if (sessionStorage.getItem("diagonose")) {
        setSelectedDiagnosesItemCodes(
          String(sessionStorage.getItem("diagonose"))
        );
      }
      if (sessionStorage.getItem("vbcPlanType")) {
        setVBCPlan(String(sessionStorage.getItem("vbcPlanType")));
        setVBCPlanName(String(sessionStorage.getItem("vbcPlanName")));
      }
      return await createApiClient().getAllPatientsList(
        searchValue,
        patientType,
        selectedDiagnosesItemCodes,
        vbcPlan,
        true,
        pageNumber,
        10
      );
    },
    {
      enabled: hasMoreData
    }
  );

  const renderDiagnoses = !isDiagnosesListError && !isLoadingDiagnosesList;

  useEffect(() => {
    if (patientData) {
      if (patientData.length === 0) {
        setHasMoreData(false);
      } else if (debouncedSearchValue || pageNumber === 1) {
        setPatientList(patientData);
      } else {
        setPatientList(prev => [...prev, ...patientData]);
      }
    }
  }, [patientData, debouncedSearchValue, pageNumber]);

  useEffect(() => {
    if (renderDiagnoses) {
      const storedDiagnosisCodes = sessionStorage.getItem("diagonose");
      let diagnosesList;
      if (storedDiagnosisCodes) {
        const storedCodesArray = storedDiagnosisCodes.split(",");

        diagnosesList = diagnosesData?.map(diagnosis => {
          return {
            ...diagnosis,
            checked: storedCodesArray.includes(diagnosis.code)
          };
        });
      } else {
        diagnosesList = diagnosesData?.map(
          (item: {
            desc: string;
            code: string;
            detail_desc: string;
            checked?: boolean;
          }) => ({
            ...item,
            checked: false
          })
        );
      }

      if (diagnosesList) {
        const half = Math.ceil(diagnosesList.length / 2);
        const firstHalf = diagnosesList.slice(0, half);
        const secondHalf = diagnosesList.slice(half);
        patientListContext.setDiagnosesMenuOne(firstHalf);
        patientListContext.setDiagnosesMenuTwo(secondHalf);
      }
    }
  }, [isLoadingDiagnosesList]);

  let diagnosisSelected: CheckboxMenuCommonInterface[] = [];
  if (!isLoadingDiagnosesList) {
    diagnosisSelected = patientListContext.diagnosesMenuOne.concat(
      patientListContext.diagnosesMenuTwo
    );
  }

  let menuOneSelectedList: CheckboxMenuCommonInterface[] = [];
  let menuTwoSelectedList: CheckboxMenuCommonInterface[] = [];
  if (!isLoadingDiagnosesList) {
    menuOneSelectedList = patientListContext.diagnosesMenuOne.filter(
      (obj: {
        desc: string;
        code: string;
        detail_desc: string;
        checked?: boolean;
      }) => obj.checked
    );

    menuTwoSelectedList = patientListContext.diagnosesMenuTwo.filter(
      (obj: {
        desc: string;
        code: string;
        detail_desc: string;
        checked?: boolean;
      }) => obj.checked
    );
  }

  const scrollContainerRef = useRef(null);
  const [scrollBottom, setScrollBottom] = useState(false);

  useEffect(() => {
    if (scrollBottom) {
      setPageNumber(prev => prev + 1);
    }
  }, [scrollBottom]);

  useEffect(() => {
    if (patientData?.length === 0) {
      return;
    } else if (pageNumber > 1 && hasMoreData) {
      getPatientList();
    }
  }, [pageNumber]);

  useEffect(() => {
    setPageNumber(1);
    setHasMoreData(true);
    setPatientList([]);
  }, [debouncedSearchValue]);

  const handleScroll = () => {
    const nativeElement: any = scrollContainerRef.current;
    const scrollPosition = Math.round(nativeElement.scrollTop);
    const isScrollBottom =
      nativeElement.clientHeight + scrollPosition + 50 >=
      nativeElement.scrollHeight;
    setScrollBottom(isScrollBottom);

    // if (isScrollBottom) {
    //   console.log("Reached near the bottom of the container");
    // }
  };

  useEffect(() => {
    const scrollContainer: any = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, [scrollContainerRef]);

  return (
    <>
      {!epicLogin && (
        <TopbarExtension>
          <ExtensionItems
            allDiagnosisChecked={allDiagnosisChecked}
            setAllDiagnosisChecked={setAllDiagnosisChecked}
            applied={diagnosisFilterApplied}
            setApplied={setDiagnosisFilterApplied}
            setSearchValue={setSearchValue}
            setPatientType={setPatientType}
            patientType={patientType}
            diagnosisSelected={diagnosisSelected}
            isLoadingDiagnosesList={isLoadingDiagnosesList}
            isDiagnosesListError={isDiagnosesListError}
            setVBCPlan={setVBCPlan}
            setVBCPlanName={setVBCPlanName}
            vbcPlanName={vbcPlanName}
            vbcList={vbcList}
            setSelectedDiagnosesItemCodes={setSelectedDiagnosesItemCodes}
            dataDisableComponent={dataDisableComponent}
            isLoadingDisableComponent={isLoadingDisableComponent}
          />
        </TopbarExtension>
      )}
      <div
        ref={scrollContainerRef}
        style={{ height: "100vh", overflow: "auto" }}
      >
        <AllPatientsTable
          patientType={patientType}
          searchValue={searchValue}
          isLoadingDiagnosesList={isLoadingDiagnosesList}
          menuOneSelectedList={menuOneSelectedList}
          menuTwoSelectedList={menuTwoSelectedList}
          diagnosesFilterApplied={diagnosisFilterApplied}
          setSelectedDiagnosesItemCodes={setSelectedDiagnosesItemCodes}
          isLoadingPatientList={isLoadingPatientList}
          patientData={patientList}
          isPatientListError={isPatientListError}
          isPatientListRefetchLoading={isPatientListRefetchLoading}
          getPatientList={getPatientList}
          vbcPlanName={vbcPlanName}
          dataDisableComponent={dataDisableComponent}
          isLoadingDisableComponent={isLoadingDisableComponent}
        />
      </div>
    </>
  );
};
